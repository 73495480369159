
// ------------------------------------------------ SHOW PAGE

const body = document.body
const navigationButton = document.getElementById('js_show-page')
navigationButton.addEventListener('click', () => {
  const nextPage = 
    body.className === "show-home" || body.className === "show-contact" 
      ? "show-participate"
      : "show-home"
  body.classList.replace(body.className, nextPage)
})

const showFormButton = document.getElementById('js_show-form')
showFormButton.addEventListener('click', () => {
  const nameInput = document.querySelectorAll('input[name="name"]')[0]
  body.classList.replace(body.className, "show-contact")
  setTimeout(() => {
    nameInput.focus()
  }, 800);
})

// ------------------------------------------------ FORM

const form = document.getElementById('js_participation-form')
const submitSuccess = document.getElementById('js_participation-form-success')

const serializeForm = () => {
	var obj = {};
	var formData = new FormData(form);
	for (var key of formData.keys()) {
		obj[key] = formData.get(key);
	}

	return JSON.stringify(obj)
};

const submit = (event) => {
  event.preventDefault()
  const endpoint = 'https://dcatesse.com/dcatesse_mailer.php'
  fetch(endpoint, {
		method: 'POST',
		body: serializeForm()
	}).then((response) => {
		if (response.ok) {
			return response.json();
		}
		return Promise.reject(response);
	}).then(() => {
    form.style.display = 'none'
    submitSuccess.style.display = 'block'
	}).catch((error) => {
		console.warn(error);
	});
}

form.addEventListener('submit', submit)
